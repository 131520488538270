import {loadConfig} from "./ConfigService";
import {
    GetClubLeaderClubResponseBody,
    GetClubLeaderEventResponseBody,
    GetMyClubsResponseBody
} from "./ClubLeaderService";
import {ClubAssociationCustomerServiceResponseBody, UserViewState} from "./MemberService";
import {hasLeadershipRoles} from "../util/Util";
import i18n from "i18next";
import {LocalDate} from "@js-joda/core";
import {getIgniteRoute} from "../util/ignite/routes.utils";
import {E3_ROUTES, IGNITE_ROUTE_KEY} from "../constants/routes";
import { getHasRegisteredEvents } from "./MemberService";
import { UserRegistrationFlow } from "./Models";
import {
    AuthParams,
    getJsonAuth,
    postJson,
    postJsonAuth, SearchAuthParams
} from "./RequestService";

// You probably shouldn't be calling any of these functions if you're not UserContext.tsx.
// TODO: Consider moving most of this into that file.

export enum LoginRealm {
    CLUBCALENDAR = 'clubcalendar',
    GROUPER = 'grouper'
}

interface LoginResponseBody {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    renewId: string,
    phone: string|null,
    postalCode: string|null,
    isEmailVerified: boolean,
    isPasswordSet: boolean,
    isIncompleteRegistration: boolean,
    hasLeadershipRoles: boolean,
    isIgniteEnabled: { isLeader: boolean, isReferral: boolean, clubId: number },
    accessToken: string,
    accessTokenExpiresAtEpochSeconds: number,
    refreshTokenExpiresAtEpochSeconds: number,
    realm: LoginRealm,
}

interface GrouperRealmLoginResponseBody {
    accessToken: string,
    id: string,
    idToken: string,
    realm: LoginRealm,
    refreshToken: string
}

interface MasqueradeUserLoginResponseBody {
    userLoginResponseBody: LoginResponseBody
    clubId: number
    relationshipTypeId: number
    isIgniteEnabled: boolean
    clubUrlFragment: string
    customerServiceEmail: string
    customerServicePhone: string
}

export interface UserClubContext {
    user: User
    clubId: number
    relationshipTypeId: number
    isIgniteEnabled: boolean
    clubUrlFragment: string
    customerServiceEmail: string
    customerServicePhone: string
}

interface IsEmailRegisteredResponseBody {
    isRegistered: boolean
    clubCustomerServicePhone?: string
}

export interface IsIgniteEnabledResponseBody {
    isLeader: boolean
    isReferral: boolean
    clubId: number
}

export interface User {
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    renewId: string|null,
    phone: string|null,
    postalCode: string|null,
    accessToken: string,
    isEmailVerified: boolean,
    isPasswordSet: boolean,
    isIncompleteRegistration: boolean,
    hasLeadershipRoles: boolean,
    isIgniteEnabled: IsIgniteEnabledResponseBody | null,
    accessTokenExpiresAtEpochSeconds: number,
    refreshTokenExpiresAtEpochSeconds: number,
}

export async function getHomePage(user: User): Promise<string> {
    if (!!user?.isIgniteEnabled) {
        if (user.isIgniteEnabled.isLeader) {
            return getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': user.isIgniteEnabled.clubId.toString() });
        }
        else if (user.isIgniteEnabled.isReferral) {
            return getIgniteRoute(IGNITE_ROUTE_KEY.CLUB_REQUESTING, { ':clubId': user.isIgniteEnabled.clubId.toString() });
        }
        return getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': user.isIgniteEnabled.clubId.toString() });
    }

    const hasRegisteredEventsResponseBody = await getHasRegisteredEvents({ authenticatedFetch });

    if (hasLeadershipRoles(user)) {
        return "/my-club";
    } else if (!hasRegisteredEventsResponseBody.hasRegisteredEvents) {
        return "/find-an-event";
    } else {
        return "/my-events";
    }
}

export function isAccessTokenExpired(user: User): boolean {
    return user.accessTokenExpiresAtEpochSeconds * 1000 <= Date.now();
}

export function isRefreshTokenExpired(user: User): boolean {
    return user.refreshTokenExpiresAtEpochSeconds * 1000 <= Date.now();
}

const USER_STORAGE_KEY = "ClubCalendarUser";
const USERS_CLUB_STORAGE_KEY = "ClubCalendarUsersClub";
const USERS_VIEW_STATE_STORAGE_KEY = "ClubCalendarUsersViewState";
const CUSTOMER_SERVICE_STORAGE_KEY = "ClubCalendarCustomerService";
const USERS_ELEGIBILITY_MODAL_SHOWN_STATE_KEY = "UsersEligibilityModalShownState";

function checkResponseOk(response: Response) {
    if (!response.ok) {
        throw new Error(`Non-ok response ${response.status} ${response.statusText} from URL ${response.url}`);
    }
}

export function getUser(): User | null {
    const serialized = localStorage.getItem(USER_STORAGE_KEY);
    return (serialized === null) ? null : JSON.parse(serialized);
}

function setUser(user: User) {
    const serialized = JSON.stringify(user);
    localStorage.setItem(USER_STORAGE_KEY, serialized);
}

export function getLanguageSelection() {
    return i18n.resolvedLanguage;
}

function clearUser() {
    localStorage.removeItem(USER_STORAGE_KEY);
}

export function clearUsersClubs() {
    localStorage.removeItem(USERS_CLUB_STORAGE_KEY);
}

export function clearUsersViewState() {
    localStorage.removeItem(USERS_VIEW_STATE_STORAGE_KEY);
}

export function clearCustomServiceInfo() {
    localStorage.removeItem(CUSTOMER_SERVICE_STORAGE_KEY);
}

export function setUsersEligibilityModalShownState(userId: number, state: boolean) {
    localStorage.setItem(`${USERS_ELEGIBILITY_MODAL_SHOWN_STATE_KEY}:${userId}`, JSON.stringify(state));
}

export function getUsersEligibilityModalShownState(userId: number): boolean | null {
    const stateSerialized = localStorage.getItem(`${USERS_ELEGIBILITY_MODAL_SHOWN_STATE_KEY}:${userId}`);

    return stateSerialized ? JSON.parse(stateSerialized) : null;
}

export async function getUsersClubs(): Promise<GetMyClubsResponseBody | null> {
    const user = getUser();
    if (user === null) {
        return null;
    }
    if (!hasLeadershipRoles(user)) {
        return null;
    }
    let serialized = localStorage.getItem(USERS_CLUB_STORAGE_KEY);
    if (serialized != null && serialized.indexOf("myClubs") < 0) {
        // If the current serialized version does not contain the new format, refresh it.
        serialized = null;
    }

    if (serialized === null) {
        await refreshUsersClubs();
        serialized = localStorage.getItem(USERS_CLUB_STORAGE_KEY);
    }
    let userClubs = (serialized === null) ? null : JSON.parse(serialized);

    return userClubs;
}

/*
 * Is the user a leader/deputy of the given club via urlFragment.
 */
export async function getUsersClub(urlFragment:string): Promise<GetClubLeaderClubResponseBody | undefined> {
    const myClubs = await getUsersClubs();

    return myClubs?.myClubs.find(club => club.urlFragment === urlFragment);
}

/*
 * Is the user a leader/deputy of the given event via urlFragment.
 */
export async function getUsersEvent(urlFragment:string): Promise<GetClubLeaderEventResponseBody | undefined> {
    const myClubs = await getUsersClubs();

    let event = undefined;
    myClubs?.myClubs.every(club => {
        event = club.events.find(event => {
            return event.urlFragment === urlFragment;
        });
        if (event !== undefined) {
            // Return false to stop iterating.
            return false;
        }
        // Return true to continue iterating.
        return true;
    });

    return event;
}

export function getClubCustomerService(clubShortCode:string) : ClubAssociationCustomerServiceResponseBody|undefined {
    let serialized = localStorage.getItem(CUSTOMER_SERVICE_STORAGE_KEY);
    if (serialized !== null) {
        const customerServiceInfo = JSON.parse(serialized) as ClubAssociationCustomerServiceResponseBody;
        if (customerServiceInfo.clubShortCode === clubShortCode) {
            return customerServiceInfo;
        }
    }
    return undefined;
}

export async function refreshClubCustomerService(clubShortCode:string) : Promise<ClubAssociationCustomerServiceResponseBody|undefined> {
    let serialized = localStorage.getItem(CUSTOMER_SERVICE_STORAGE_KEY);
    if (serialized !== null) {
        const customerServiceInfo = JSON.parse(serialized) as ClubAssociationCustomerServiceResponseBody;
        if (customerServiceInfo.clubShortCode === clubShortCode) {
            return customerServiceInfo;
        }
    }
    const config = await loadConfig();
    const clubCustomerServiceRequest =
        new Request(
            `${config.apiOrigin}/clubs/get-customer-service/${clubShortCode}`,
            {method: "GET", headers: {"Accept": "application/json"}});

    const clubCustomerServiceResponse = await fetch(clubCustomerServiceRequest);

    if (clubCustomerServiceResponse === null || !clubCustomerServiceResponse.ok) {
        return;
    }

    const customerServiceInfo: ClubAssociationCustomerServiceResponseBody = await clubCustomerServiceResponse.json();
    serialized = JSON.stringify(customerServiceInfo);
    localStorage.setItem(CUSTOMER_SERVICE_STORAGE_KEY, serialized);
    return customerServiceInfo;
}

export async function refreshUsersClubs() {
    const config = await loadConfig();
    const myClubsRequest =
        new Request(
            `${config.apiOrigin}/club-leader/my-clubs?omitEvents=true&omitClubLeaders=true`,
            {method: "GET", headers: {"Accept": "application/json"}});

    const myClubsResponse = await authenticatedFetch(myClubsRequest);

    if (myClubsResponse === null) {
        return;
    }

    if (!myClubsResponse.ok) {
        return;
    }

    /// user doesn't have any clubs yet.
    if (myClubsResponse.status === 204) {
        return;
    } else {
        const myClubs: GetMyClubsResponseBody = await myClubsResponse.json();
        const serialized = JSON.stringify(myClubs);
        localStorage.setItem(USERS_CLUB_STORAGE_KEY, serialized);
    }
}

/**
 * Updates the current user's isIgniteEnabled status. Useful for when a user confirms a referral and would now have
 * membership with a new club.
 */
export function setIsIgniteEnabled(isIgniteEnabled:IsIgniteEnabledResponseBody) {
    let user = getUser();
    if (user !== null) {
        user.isIgniteEnabled = isIgniteEnabled;
        setUser(user);
    }
}

export function setUserViewState(viewState : UserViewState|null) {
    const serialized = JSON.stringify(viewState);
    localStorage.setItem(USERS_VIEW_STATE_STORAGE_KEY, serialized);
}

export function getUserViewState(): UserViewState|null {
    const user = getUser();
    if (user === null) {
        return null;
    }
    let serialized = localStorage.getItem(USERS_VIEW_STATE_STORAGE_KEY);
    if (serialized === null) {
        return null;
    }
    const viewState = JSON.parse(serialized) as UserViewState;
    return viewState;
}

/**
 * Logs out the current user and invalidates the current refresh token.
 *
 * This function does nothing if the user is not logged in.
 */
export async function logout(): Promise<void> {
    clearUser();
    clearUsersClubs();
    clearUsersViewState();
    clearCustomServiceInfo();

    const config = await loadConfig();
    const url = `${config.apiOrigin}/accounts/logout`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include"
    });

    const response = await fetch(request);

    checkResponseOk(response);
}

/**
 * Logs out the current user and invalidates all refresh tokens for this user.
 *
 * This function does nothing if the user is not logged in.
 */
export async function logoutAll(): Promise<void> {
    clearUser();
    clearUsersClubs();
    clearUsersViewState();
    clearCustomServiceInfo();

    const config = await loadConfig();
    const url = `${config.apiOrigin}/accounts/logoutall`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include"
    });

    const response = await fetch(request);

    checkResponseOk(response);
}

async function handleLoginResponse(response: Response): Promise<User | null> {
    const config = await loadConfig();

    if (response.status === 401) {
        return null;
    }

    checkResponseOk(response);

    let responseBody: LoginResponseBody | GrouperRealmLoginResponseBody = await response.json();

    if (responseBody.realm === LoginRealm.GROUPER) {
        window.location.href = `${config.grouperUrl}/dashboard?refreshToken=${(responseBody as GrouperRealmLoginResponseBody).refreshToken}`;
    }

    responseBody = responseBody as LoginResponseBody;
    const user: User = {
        id: responseBody.id,
        email: responseBody.email,
        firstName: responseBody.firstName,
        lastName: responseBody.lastName,
        renewId: responseBody.renewId,
        phone: responseBody.phone,
        postalCode: responseBody.postalCode,
        isEmailVerified: responseBody.isEmailVerified,
        isPasswordSet: responseBody.isPasswordSet,
        isIncompleteRegistration: responseBody.isIncompleteRegistration,
        hasLeadershipRoles: responseBody.hasLeadershipRoles,
        isIgniteEnabled: responseBody.isIgniteEnabled,
        accessToken: responseBody.accessToken,
        accessTokenExpiresAtEpochSeconds: responseBody.accessTokenExpiresAtEpochSeconds,
        refreshTokenExpiresAtEpochSeconds: responseBody.refreshTokenExpiresAtEpochSeconds
    };

    setUser(user);
    await refreshUsersClubs();
    return user;
}

/**
 * Returns the user with the specified email and password if those credentials
 * are accepted by the API.  Returns `null` if either one is incorrect.  Throws
 * an error if the API can't be reached or there is an unexpected HTTP response
 * from the API.
 *
 * @param email the user's email address
 * @param password the user's plaintext password
 */
export async function login(email: string, password: string): Promise<User | null> {
    const config = await loadConfig();
    const useAuthService = config.flags ? config.flags.USE_AUTH_SERVICE : false;
    const url = useAuthService ? `${config.authApiOrigin}/login` : `${config.apiOrigin}/accounts/login`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email,
            password
        })
    });
    return handleLoginResponse(await fetch(request));
}

/**
 * Returns the user with the specified token if those credentials are accepted
 * by the API.  Returns `null` if it is invalid.  Throws an error if the API
 * can't be reached or there is an unexpected HTTP response from the API.
 *
 * @param token the token
 */
export async function tokenLogin(token: string): Promise<User | null> {
    const config = await loadConfig();
    const useAuthService = config.flags ? config.flags.USE_AUTH_SERVICE : false;
    const url = useAuthService ? `${config.authApiOrigin}/login/token` : `${config.apiOrigin}/accounts/token-login`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            token,
        })
    });
    const response = await fetch(request);
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return handleLoginResponse(response);
}

/**
 * Returns the user with the specified token if the provided credentials are accepted by the API.
 * If the token is valid, the user object is returned; otherwise, it returns `null`.
 * Throws an error if the API can't be reached or there is an unexpected HTTP response from the API.
 *
 * @param userId - The user ID associated with the token.
 * @param loginToken - The login token to authenticate and authorize the user.
 */
export async function tokenBasedLogin(userId: number, loginToken: string): Promise<User | null> {
    const config = await loadConfig();
    const useAuthService = config.flags ? config.flags.USE_AUTH_SERVICE : false;
    const url = useAuthService ? `${config.authApiOrigin}/login/token-and-user-id` : `${config.apiOrigin}/accounts/token-based-login`;

    const request = new Request(url, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            userId,
            loginToken,
        })
    });
    const response = await fetch(request);
    if (!response.ok) {
        throw new Error(await response.text());
    }
    return handleLoginResponse(response);
}

export async function testIfEmailRegistered(emailAddress: string, clubShortCode: string | null = null) : Promise<IsEmailRegisteredResponseBody> {
    const config = await loadConfig();
    const url = `${config.apiOrigin}/accounts/testEmailRegistration`;
    const request = new Request(url, {
        method: 'POST',
        body: JSON.stringify({
            emailAddress: emailAddress,
            clubShortCode: clubShortCode
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });
    const response = await fetch(request);
    checkResponseOk(response);
    const responseBody: IsEmailRegisteredResponseBody = await response.json();
    return responseBody;
}

export interface PayerInformationParameters {
    payerId: number,
    insuranceId: string,
    healthPlanName: string | null,
    zipCode: string,
    phoneNumber: string,
    dateOfBirth: LocalDate
}

export interface LeaderRegistrationParameters {
    email : string,
    phone : string,
    password : string,
    firstName : string,
    lastName : string,
    zipCode : string,
    deputyLeaderInviteUrlFragment: string | null
}

export interface AcblInitialRegistrationRequestBody {
    email : string,
    utmCampaign?: string,
    utmReferringClub?: string,
    registrationFlow: UserRegistrationFlow
}

export interface AcblInitialRegistrationResponseBody {
    userId: number,
    emailSent: boolean,
    sendToLogin: boolean,
    token: string | null,
}

export interface CheckAcblMemberNumberRequestParams {
    acblMemberNumber: string
}

export interface CheckAcblMemberNumberResponseBody {
    available: boolean,
    valid: boolean,
}

export interface AcblRegistrationRequestBody {
    phone: string,
    firstName: string,
    lastName: string,
    zipCode: string | null,
    renewId: string | null,
    healthPlanName: string | null,
    payerId: number | null,
    dateOfBirth: string | null,
    acblMemberNumber: string | null,
    completeRegistration: boolean,
    optInSweepstakes: boolean,
    utmReferringClub: string | null,
}

export interface AcblRegistrationResponseBody {
    phone: string,
    firstName: string,
    lastName: string,
    zipCode: string | null,
    renewId: string | null,
    payerId: number | null,
    dateOfBirth: string | null,
    acblMemberNumber: string | null,
    healthPlanName: string | null,
    optInSweepstakes: boolean,
}

export interface MemberRegistrationParameters {
    email : string,
    phone : string,
    password : string,
    firstName : string,
    lastName : string,
    zipCode : string,
    dateOfBirth? : string,
    renewId : string | null
    healthPlanName?: string | null
    payerId? : number
    passionIds? : number[],
    utmCampaign?: string,
    utmReferringClub?: string,
    registrationFlow: UserRegistrationFlow
}

export interface NewMemberRegistrationParameters {
    passionIds? : number[],
    firstName : string,
    lastName : string,
    email : string,
    password : string,
    utmCampaign?: string,
    utmReferringClub?: string,
    registrationFlow: UserRegistrationFlow
}

export interface CustomEnrollmentRegistrationParameters {
    email : string,
    password : string,
    hearAboutUs: number,
    referralCode: string,
    hearAboutUsTextInput: string,
    shortCode: string,
    utmCampaign?: string,
    utmReferringClub?: string,
    registrationFlow: UserRegistrationFlow
    memberId: string,
    lastName?: string,
}

export interface CustomEnrollmentPersonalInformationParameters {
    firstName : string,
    lastName : string,
    memberId: string,
    externalActivityProfileUrl: string,
    userReportedActiveMembership: boolean,
    agreements: any,
    shortCode: string
}

export async function registerLeader(parameters : LeaderRegistrationParameters) : Promise<Response> {
    const config = await loadConfig();
    const url = `${config.apiOrigin}/accounts/registerLeader`;
    const request = new Request(url, {
        method: 'POST',
        body: JSON.stringify(parameters),
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": getLanguageSelection()
        }
    });
    const response = await fetch(request);
    checkResponseOk(response);
    const resonseJson = await response.json();
    return resonseJson.userId;
}

export async function registerMember(parameters : MemberRegistrationParameters): Promise<string> {
    const config = await loadConfig();
    const url = `${config.apiOrigin}/accounts/registerMember`;
    const request = new Request(url, {
        method: 'POST',
        body: JSON.stringify(parameters),
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": getLanguageSelection()
        }
    });
    const response = await fetch(request);
    if (response?.ok) {
        const responseJson = await response.json();

        setUsersEligibilityModalShownState(responseJson.userId, false);

        return responseJson.userId;
    }
    const errorMessage = await response.text();
    throw new Error(errorMessage);
}

export async function registerAcblMember(parameters: AcblInitialRegistrationRequestBody): Promise<AcblInitialRegistrationResponseBody> {
    const config = await loadConfig();
    const response = await postJson({
        url: `${config.apiOrigin}/accounts/register-acbl-member`,
        data: parameters
    });
    return await response.json();
}

interface UpdateAcblMemberParams extends AuthParams {
    body: AcblRegistrationRequestBody
}

export async function getAcblMember(params: AuthParams): Promise<AcblRegistrationResponseBody> {
    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch: params.authenticatedFetch,
        url: `${config.apiOrigin}/accounts/acbl-member-registration`
    });
}

export async function updateAcblMember(params: UpdateAcblMemberParams): Promise<Response> {
    const config = await loadConfig();
    return await postJsonAuth({
        authenticatedFetch: params.authenticatedFetch,
        url: `${config.apiOrigin}/accounts/acbl-member-registration`,
        data: params.body
    });
}

export async function checkAcblMemberNumber(params: SearchAuthParams<CheckAcblMemberNumberRequestParams>)
    : Promise<CheckAcblMemberNumberResponseBody> {
    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch: params.authenticatedFetch,
        url: `${config.apiOrigin}/accounts/check-acbl-member-number`,
        searchParams: params.searchParams
    });
}

export async function registerNewMember(params: NewMemberRegistrationParameters): Promise<Response> {
    const config = await loadConfig();
    const response =  await postJson({
        url: `${config.apiOrigin}/accounts/register-new-member`,
        data: params
    });
    const responseJson = await response.json();
    return responseJson.userId;
}

export async function registerMemberViaCustomEnrollment(params: CustomEnrollmentRegistrationParameters): Promise<Response> {
    const config = await loadConfig();

    const response =  await postJson({
        url: `${config.apiOrigin}/accounts/register`,
        data: params
    });
    const responseJson = await response.json();
    return responseJson.userId;
}

/**
 * Attempts to obtain a new access token using the user's current refresh token,
 * which is stored as an HTTP-only cookie.  If this refresh is successful, the
 * refreshed user data is persisted to local storage and returned from this
 * method.  If the refresh fails due to an expired refresh token, then the user
 * data is cleared from local storage and this method returns `null`.  If the
 * refresh fails due to some other unexpected response from the API, this method
 * throws an error.
 */
export async function refreshUser() {
    const config = await loadConfig();
    const useAuthService = config.flags ? config.flags.USE_AUTH_SERVICE : false;
    const refreshUrl = useAuthService ? `${config.authApiOrigin}/refresh` : `${config.apiOrigin}/accounts/refresh`;

    const refreshRequest = new Request(refreshUrl, {
        method: "POST",
        credentials: "include"
    });

    const refreshResponse = await fetch(refreshRequest);

    if (refreshResponse.status === 401) {
        // This could mean:
        //   - The refresh token expired (most likely).
        //   - The refresh token cookie was deleted client-side before it expired.
        //   - The refresh token was deleted server-side before it expired.
        //
        // In any case, it means the user's session is over, and they must log in again.
        clearUser();
        clearUsersClubs();
        clearUsersViewState();
        clearCustomServiceInfo()
        window.location.href = E3_ROUTES.LOGIN
        return null;
    }

    checkResponseOk(refreshResponse);

    const responseBody: LoginResponseBody = await refreshResponse.json();

    let user = {
        id: responseBody.id,
        email: responseBody.email,
        firstName: responseBody.firstName,
        lastName: responseBody.lastName,
        renewId: responseBody.renewId,
        phone: responseBody.phone,
        postalCode: responseBody.postalCode,
        isEmailVerified: responseBody.isEmailVerified,
        isPasswordSet: responseBody.isPasswordSet,
        isIncompleteRegistration: responseBody.isIncompleteRegistration,
        hasLeadershipRoles: responseBody.hasLeadershipRoles,
        isIgniteEnabled: responseBody.isIgniteEnabled,
        accessToken: responseBody.accessToken,
        accessTokenExpiresAtEpochSeconds: responseBody.accessTokenExpiresAtEpochSeconds,
        refreshTokenExpiresAtEpochSeconds: responseBody.refreshTokenExpiresAtEpochSeconds
    };

    setUser(user);
    return user;
}

/**
 * Modifies the specified request to include authentication from the current
 * user, and then sends that modified request.  Returns the non-`null` response
 * if the authentication was added to the request and that authentication was
 * accepted by the server.  Returns `null` if this function is unable to add
 * working authentication for some reason, such as the user not being logged in,
 * or the user's refresh token having expired, or the user's access token not
 * being accepted for whatever reason (such as changing the JWT signing keys
 * server-side).
 *
 * Note that a non-`null` response does not necessarily indicate a successful
 * request.  Non-`null` indicates that *authentication* was accepted, but the
 * request may fail for other reasons.  It is the caller's responsibility to
 * examine the response (especially the status code) and to react accordingly.
 */
export async function authenticatedFetch(request: Request): Promise<Response | null> {
    let user = getUser();

    if (user === null || isRefreshTokenExpired(user)) {
        // The user is not logged in.  There is no hope of authenticating
        // without a manual login.
        return null;
    }

    if (isAccessTokenExpired(user)) {
        user = await refreshUser();

        if (user === null) {
            // We thought the user was logged in, but it turns out they don't
            // have a working refresh token, so they must manually log in.
            return null;
        }
    }

    const combinedHeaders = new Headers();
    combinedHeaders.append("Authorization", `Bearer ${user.accessToken}`);
    combinedHeaders.append('Accept-Language', getLanguageSelection());
    for (const [ headerName, headerValue ] of Array.from(request.headers.entries())) {
        combinedHeaders.append(headerName, headerValue);
    }

    const authenticatedRequest = new Request(request, {
        credentials: "include",
        headers: combinedHeaders
    });

    const response = await fetch(authenticatedRequest);

    if (response.status === 401) {
        // If this 401 response is a 401 because of the access token, then
        // return null, indicating that logging in again might fix the problem.
        // (This situation should be unusual because we checked that the access
        // token was non-expired moments ago.  It could mean the access token
        // *just* expired in these past few moments, or it could mean we changed
        // the JWT signing keys server-side.)
        //
        // Otherwise, if this 401 is happening for some other endpoint-specific
        // reason, let the caller deal with it as they see fit.
        const wwwAuthenticate = response.headers.get("WWW-Authenticate");
        if (wwwAuthenticate !== null && wwwAuthenticate.startsWith("Bearer")) {
            return null;
        }
    }

    return response;
}

/**
 * Like {@link login}, except this function consumes a masquerade token
 * generated by an admin instead of an email and password entered by a user.
 */
export async function masquerade(masqueradeToken: string): Promise<UserClubContext | null> {
    const config = await loadConfig();
    const useAuthService = config.flags ? config.flags.USE_AUTH_SERVICE : false;
    const url = useAuthService ? `${config.authApiOrigin}/masquerade` : `${config.apiOrigin}/accounts/masquerade`;
    const request = new Request(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({ masqueradeToken })
    });
    const response = await fetch(request);

    if (response.status === 401) {
        return null;
    }

    checkResponseOk(response);

    const responseBody: MasqueradeUserLoginResponseBody = await response.json();

    const user: User = {
        id: responseBody.userLoginResponseBody.id,
        email: responseBody.userLoginResponseBody.email,
        firstName: responseBody.userLoginResponseBody.firstName,
        lastName: responseBody.userLoginResponseBody.lastName,
        renewId: responseBody.userLoginResponseBody.renewId,
        phone: responseBody.userLoginResponseBody.phone,
        postalCode: responseBody.userLoginResponseBody.postalCode,
        isEmailVerified: responseBody.userLoginResponseBody.isEmailVerified,
        isPasswordSet: responseBody.userLoginResponseBody.isPasswordSet,
        isIncompleteRegistration: responseBody.userLoginResponseBody.isIncompleteRegistration,
        hasLeadershipRoles: responseBody.userLoginResponseBody.hasLeadershipRoles,
        isIgniteEnabled: responseBody.userLoginResponseBody.isIgniteEnabled,
        accessToken: responseBody.userLoginResponseBody.accessToken,
        accessTokenExpiresAtEpochSeconds: responseBody.userLoginResponseBody.accessTokenExpiresAtEpochSeconds,
        refreshTokenExpiresAtEpochSeconds: responseBody.userLoginResponseBody.refreshTokenExpiresAtEpochSeconds
    };

    setUser(user);

    const userClubContext: UserClubContext = {
        user: user,
        clubId: responseBody.clubId,
        relationshipTypeId: responseBody.relationshipTypeId,
        isIgniteEnabled: responseBody.isIgniteEnabled,
        clubUrlFragment: responseBody.clubUrlFragment,
        customerServiceEmail: responseBody.customerServiceEmail,
        customerServicePhone: responseBody.customerServicePhone
    }

    return userClubContext;
}