import React, {useState, useEffect} from "react";
import * as UserService from "../services/UserService";
import {
    clearUsersClubs, clearUsersViewState, getClubCustomerService,
    IsIgniteEnabledResponseBody,
    User
} from "../services/UserService";
import {
    GetContactPhoneNumber,
    PhoneNumber,
    PhoneNumberOverride
} from "../components/common/ContactPhoneNumber";
import {GetMyClubsResponseBody} from "../services/ClubLeaderService";
import {UserViewState} from "../services/MemberService";
import {CLUB_ROLES} from "../constants/dashboard";
import {formatPhoneNumberForDisplay, formatPhoneNumberForTel} from "../util/ignite/MyInfo.utils";
import { ClubVerificationStatus } from "../types/club";
import {customEnrollmentRegex} from "../util/CustomEnrollment";

export interface UserState {
    /**
     * The currently logged-in user, or `null` if not logged in.
     */
    user: User | null,

    /**
     * Used to keep track of the user's viewState - club vs. member view state, club context, leader status, etc.
     */
    viewState: UserViewState | null,

    /**
     * Attempts to log in as the user with the specified email and password.  If
     * the login is accepted by the server, this function sets {@see user} to a
     * non-`null` value and returns `true`.  Otherwise, this function sets
     * {@code user} to `null` and returns `false`.
     */
    login: (email: string, password: string) => Promise<boolean>,

    /**
     * Attempts to log in as the user with the associated token.  If the login
     * is accepted by the server, this function sets {@see user} to a
     * non-`null` value and returns `true`.  Otherwise, this function sets
     * {@code user} to `null` and returns `false`.
     */
    tokenLogin: (token: string) => Promise<boolean>,

    /**
     * Attempts to log in as the user with the associated token. If the login
     * is accepted by the server, this function sets `user` to a non-`null` value
     * and returns `true`. Otherwise, this function sets `user` to `null` and
     * returns `false`.
     *
     * @param userId - The user ID associated with the token.
     * @param loginToken - The login token to authenticate the user.
     * @returns A Promise that resolves to `true` if the login is successful; otherwise, resolves to `false`.
     */
    tokenBasedLogin: (userId: number, loginToken: string) => Promise<boolean>,

    /**
     * Logs out the user if they are currently logged in.  Sets {@code user} to
     * `null`.  If not currently logged in, this function does nothing.
     */
    logout: () => Promise<void>,

    /**
     * Logs out the user and expires all refresh tokens on the server for that
     * user, which effectively means that the user will be logged out from all
     * other active sessions shortly (when the access tokens expire).  Sets
     * {@code user} to `null`.  If not currently logged in, this function does
     * nothing.
     */
    logoutAll: () => Promise<void>,

    /**
     * Attempts to consume the specified masquerade token, which allows an admin
     * to masquerade as a user.  If the masquerade attempt is accepted by the
     * server, this function sets {@see user} to a non-`null` value and returns
     * `true`.  Otherwise, this function sets {@code user} to `null` and returns
     * `false`.
     */
    masquerade: (masqueradeToken: string) => Promise<boolean>,

    /**
     * Modifies the specified request to include authorization from the current
     * user (in the form of an access token and a refresh token), sends the
     * modified request, and then returns the response.  If the access token has
     * expired but the refresh token has not expired, this function will obtain
     * another access token from the server before sending the specified
     * request.  If the user is not logged in, or the refresh token has expired,
     * or the server rejects the request with `401 Unauthorized` (indicating the
     * access token was not accepted for some reason -- maybe we changed the
     * signing key used for the tokens), then this function sets {@see user} to
     * `null` and returns `null`.
     */
    authenticatedFetch: (request: Request) => Promise<Response | null>

    /*
     * Refreshes the user.  It may be necessary to refresh a user after
     * setting certain properties, eg the email verified flag.
     *
     *
     */
    refreshUser: () => Promise<void>,

     /*
     * Sync context user with localStorage user.
     */

    syncUser: () => void,

    /*
     * Returns true if a user is logged in.
     */
    isLoggedIn: () => boolean,

    /*
     * Returns true if the user is a leader or deputy on any clubs.
     */
    hasLeadershipRoles: () => boolean,

    /*
     * Returns true if the user is a leader or deputy on the given club.
     */
    hasLeadershipRole: (clubUrlFragment:string|undefined) => Promise<boolean>,

    /*
     * Returns an IsIgniteEnabledResponseBody denoting if this is an ignite user, an ignite leader, has pending referrals,
     * and which club they have most recently been affiliated with.
     */
    isIgniteEnabled: () => IsIgniteEnabledResponseBody|undefined|null,

    /*
     * Returns the clubUrlFragment if the user is a leader or deputy of the club hosting given event.
     */
    getClubUrlFragmentIfLeader: (eventUrlFragment:string|undefined) => Promise<string|undefined>,

    /*
     * Returns the appropriate support contact phone number for this user.
     */
    getContactPhoneNumber: ({override, pathname}: {override?: PhoneNumberOverride|undefined, pathname?: string|undefined}) => PhoneNumber,

    /*
     * Returns the appropriate support contact email for this user. This is to be used primarily for members of clubs/associations.
     * Other email addresses are used throughout for varying purposes and remain untouched:
     *   support@hellogrouper.com
     *   events@hellogrouper.com
     *   privacy@hellogrouper.com
     *   ClubHelp@hellogrouper.com
     *   help@hellogrouper.com
     *   legal@hellogrouper.com
     */
    getContactEmail: (pathname: string) => string,

    /*
     * Returns the current user's clubs, if they are a Club Leader or Deputy, and they have a club.
     * Otherwise returns null.
     */
    getUsersClubs: () => Promise<GetMyClubsResponseBody | null>,

    /*
     * Set the user's viewState - club vs. member view state, club context, leader status, etc.
     */
    setUserViewState: (viewState:UserViewState|null) => void
}

const initialUserState: UserState = {
    user: null,
    viewState: null,
    login: () => Promise.resolve(false),
    tokenLogin: () => Promise.resolve(false),
    tokenBasedLogin: () => Promise.resolve(false),
    logout: () => Promise.resolve(),
    logoutAll: () => Promise.resolve(),
    masquerade: () => Promise.resolve(false),
    authenticatedFetch: () => Promise.resolve(null),
    refreshUser: () => Promise.resolve(),
    syncUser: () => null,
    isLoggedIn: () => false,
    hasLeadershipRoles: () => false,
    hasLeadershipRole: () => Promise.resolve(false),
    isIgniteEnabled: () => undefined,
    getClubUrlFragmentIfLeader: () => Promise.resolve(undefined),
    getContactPhoneNumber: () => ({ display: '', tel: '' }),
    getContactEmail: () => '',
    getUsersClubs: () => Promise.resolve(null),
    setUserViewState: () => null,
};

/**
 * Exposes the current user to a JSX element.
 *
 * Example usage:
 *
 * ```
 * function MyCustomElement(): JSX.Element {
 *     const {user} = useContext(UserContext);
 *
 *     return (
 *         <div>
 *             {user !== null && (
 *                 <p>You're logged in as {user.email}</p>
 *             )}
 *             {user === null && (
 *                 <p>You're logged out</p>
 *             )}
 *         </div>
 *     );
 * }
 * ```
 */
export const UserContext: React.Context<UserState> =
    React.createContext(initialUserState);

/**
 * Allows child elements to use {@link UserContext}.  This should be applied at the application level.
 */
export function UserContextProvider({ children }: React.PropsWithChildren<React.ReactNode>) {
    const [user, setUser] = useState<User | null>(UserService.getUser());
    const [viewState, setViewState] = useState<UserViewState | null>(UserService.getUserViewState());

    async function login(email: string, password: string): Promise<boolean> {
        const user = await UserService.login(email, password);
        setUser(user);
        return user !== null;
    }

    async function tokenLogin(token: string): Promise<boolean> {
        const user = await UserService.tokenLogin(token);
        setUser(user);
        return user !== null;
    }

    async function tokenBasedLogin(userdId: number, loginToken: string): Promise<boolean> {
        const user = await UserService.tokenBasedLogin(userdId, loginToken);
        setUser(user);
        return user !== null;
    }

    async function logout() {
        await UserService.logout();
        setUser(null);
    }

    async function logoutAll() {
        await UserService.logoutAll();
        setViewState(null)
        setUser(null);
    }

    async function refreshUser() {
        const user = await UserService.refreshUser();
        setUser(user);
    }

    function syncUser() {
        const userFromStore = UserService.getUser();
        setUser(userFromStore);
    }

    async function masquerade(masqueradeToken: string): Promise<boolean> {
        const userClubContext = await UserService.masquerade(masqueradeToken);
        const user = userClubContext == null ? null : userClubContext.user;
        clearUsersClubs();
        setUser(user);
        return user !== null;
    }

    async function authenticatedFetch(request: Request): Promise<Response | null> {
        const response = await UserService.authenticatedFetch(request);
        if (response == null) {
            setUser(null);
        }
        return response;
    }

    function isLoggedIn():boolean {
        return user !== null;
    }

    function hasLeadershipRoles():boolean {
        return user !== null && user.hasLeadershipRoles;
    }

    async function hasLeadershipRole(clubUrlFragment:string|undefined):Promise<boolean> {
        if (clubUrlFragment === undefined) {
            return false;
        }
        let club = await UserService.getUsersClub(clubUrlFragment);
        return club !== undefined;
    }

    function isIgniteEnabled():IsIgniteEnabledResponseBody|undefined|null {
        return user?.isIgniteEnabled;
    }

    async function getClubUrlFragmentIfLeader(eventUrlFragment:string|undefined):Promise<string|undefined> {
        if (eventUrlFragment === undefined) {
            return undefined;
        }
        let event = await UserService.getUsersEvent(eventUrlFragment);
        return event !== undefined ? event.clubUrlFragment : undefined;
    }

    function getContactPhoneNumber({override = undefined, pathname = undefined}: {override?: PhoneNumberOverride|undefined, pathname?: string|undefined} = {}): PhoneNumber {
        return GetContactPhoneNumber(override, user, viewState, pathname);
    }

    function getContactEmail(pathname: string): string {
        const customEnrollmentFlow = pathname.toLowerCase().startsWith("/p/");
        if (customEnrollmentFlow) {
            const customEnrollmentMatching = pathname.match(customEnrollmentRegex);
            const customEnrollmentShortCode = customEnrollmentMatching ? customEnrollmentMatching[1] : undefined;

            if (customEnrollmentShortCode) {
                const customServiceInfo = getClubCustomerService(customEnrollmentShortCode);
                if (customServiceInfo && customServiceInfo.customerServiceEmail) {
                    return customServiceInfo.customerServiceEmail;

                }
            }
        }

        // If this club/association has customer support numbers defined, use those.
        if (viewState?.currentClubSupportEmail) {
            return viewState.currentClubSupportEmail;
        }

        return "info@hellogrouper.com";
    }

    async function getUsersClubs(): Promise<GetMyClubsResponseBody | null> {
        if (!isLoggedIn()) {
            return null;
        }
        if (!hasLeadershipRoles()) {
            return null;
        }

        return await UserService.getUsersClubs();
    }

    function setUserViewState(viewState: UserViewState|null)  {
        UserService.setUserViewState(viewState);
        setViewState(viewState);
    }

    // Clear the user when their refresh token expires, since having an expired
    // refresh token means they are effectively logged out.
    useEffect(
        () => {
            if (user === null) {
                return;
            }

            const expiresInMillis =
                user.refreshTokenExpiresAtEpochSeconds * 1000 - Date.now();

            const timeoutId =
                setTimeout(
                    () => {
                        setUser(null);
                    },
                    expiresInMillis);

            return () => {
                clearTimeout(timeoutId);
            };
        },
        [user]);

    useEffect(() => {
        // Invalidate the user information when the app loads or the users backs to browser/tab
        // to ensure it is up-to-date.
        const handleTabVisibilityChange = (event: Event) => {
            const doc = event.target as Document;
            if (doc.hidden && isLoggedIn()) {
                refreshUser();
            }
        }

        if (isLoggedIn()) {
            refreshUser();
        }

        document.addEventListener('visibilitychange', handleTabVisibilityChange);
        document.addEventListener('focus', handleTabVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleTabVisibilityChange);
            document.removeEventListener('focus', handleTabVisibilityChange);
          };
    }, []);

    const currentUserState: UserState = {
        user,
        viewState,
        login,
        tokenLogin,
        tokenBasedLogin,
        logout,
        logoutAll,
        masquerade,
        authenticatedFetch,
        refreshUser,
        isLoggedIn,
        hasLeadershipRoles,
        hasLeadershipRole,
        isIgniteEnabled,
        getClubUrlFragmentIfLeader,
        getContactPhoneNumber,
        getContactEmail,
        getUsersClubs,
        setUserViewState,
        syncUser,
    };

    return (
        <UserContext.Provider
            value={currentUserState}
            children={children} />
    );
}